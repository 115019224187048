import { gsap } from "gsap"
function threeVideoLayoutPlayAnimation({
  activeVideoRef,
  nonActiveVideoRefs,
  activeLinkRef,
  nonActiveLinksRefs,
  textRefs,
  activeVideoIndex,
}) {
  gsap
    .timeline({
      defaults: {
        duration: 0.5,
      },
    })
    .addLabel("start")
    .to(activeVideoRef, {
      autoAlpha: 1,
    })
    .to(
      nonActiveVideoRefs,
      {
        autoAlpha: 0.1,
      },
      "start"
    )
    .to(
      activeLinkRef,
      {
        autoAlpha: 1,
      },
      "start"
    )
    .to(
      nonActiveLinksRefs,
      {
        autoAlpha: 0.1,
      },
      "start"
    )
    .to(
      textRefs[0].current,
      {
        autoAlpha: activeVideoIndex === 0 ? 1 : 0,
        x: activeVideoIndex === 0 ? 0 : 480,
      },
      "start"
    )
    .to(
      textRefs[1].current,
      {
        autoAlpha: activeVideoIndex === 1 ? 1 : 0,
        x: activeVideoIndex === 1 ? 0 : -480,
      },
      "start"
    )
    .to(
      textRefs[2].current,
      {
        autoAlpha: activeVideoIndex === 1 ? 1 : 0,
        x: activeVideoIndex === 1 ? 0 : 480,
      },
      "start"
    )
    .to(
      textRefs[3].current,
      {
        autoAlpha: activeVideoIndex === 2 ? 1 : 0,
        x: activeVideoIndex === 2 ? 0 : -480,
      },
      "start"
    )
}

export default threeVideoLayoutPlayAnimation
