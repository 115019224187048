import React from "react"

const CircleMask = ({ children }) => {
  return (
    <div className="flex min-h-screen min-w-screen relative">
      <div
        className={`w-95vw h-95vw md:w-80vw md:h-80vw lg:w-65vw lg:h-65vw 2xl:w-50vw 2xl:h-50vw relative overflow-hidden rounded-full m-auto flex z-10`}
      >
        {children}
      </div>
    </div>
  )
}

export default CircleMask
