import React from "react"
import Video from "./Video"
import { Link } from "gatsby"
import CircleMask from "./CircleMask"
import ThreeVideoLayoutTexts from "./ThreeVideoLayoutTexts"
import threeVideoLayoutPauseAnimation from "../../gsap-animations/threeVideoLayoutPauseAnimation"
import threeVideoLayoutPlayAnimation from "../../gsap-animations/threeVideoLayoutPlayAnimation"
import useTabIsUsed from "../../hooks/useTabIsUsed"
import getClassNamesByTabIsUsedState from "../../helpers/getClassNamesByTabIsUsedState"

const ThreeVideosLayout = ({ data, links }) => {
  const tabIsUsed = useTabIsUsed()
  const [activeVideoIndex, setActiveVideoIndex] = React.useState(null)
  const linksRefs = links.map(() => React.createRef())
  const videoRefs = links.map(() => React.createRef())
  const textRefs = Array(4)
    .fill()
    .map(() => React.createRef())
  //animate based on activeVideoIndex
  React.useEffect(() => {
    //if there is no active video
    if (activeVideoIndex === null) {
      videoRefs.forEach(ref => ref.current.pause())
      threeVideoLayoutPauseAnimation({
        videoRefs,
        linksRefs,
        textRefs,
      })
    }
    //if there is an active video
    if (activeVideoIndex !== null) {
      const getActiveRef = refs => refs[activeVideoIndex].current
      const getNonActiveRef = refs =>
        [
          ...refs.slice(0, activeVideoIndex),
          ...refs.slice(activeVideoIndex + 1),
        ].map(ref => ref.current)

      //define video refs
      const activeVideoRef = getActiveRef(videoRefs)
      const nonActiveVideoRefs = getNonActiveRef(videoRefs)
      //define link refs
      const activeLinkRef = getActiveRef(linksRefs)
      const nonActiveLinksRefs = getNonActiveRef(linksRefs)

      // play video, pause the rest and run animations
      activeVideoRef.play()
      nonActiveVideoRefs.forEach(ref => ref.pause())
      threeVideoLayoutPlayAnimation({
        activeVideoRef,
        nonActiveVideoRefs,
        activeLinkRef,
        nonActiveLinksRefs,
        textRefs,
        activeVideoIndex,
      })
    }
  }, [activeVideoIndex, linksRefs, videoRefs, textRefs])

  const getVideoObjectPosition = index => {
    switch (index) {
      case 0: {
        return "object-left"
      }
      case 1: {
        return "object-center"
      }
      case 2: {
        return "object-right"
      }
      default: {
        return "object-center"
      }
    }
  }
  return (
    <CircleMask>
      <ThreeVideoLayoutTexts textRefs={textRefs} data={data} />
      {links.map(
        ({ video, poster, id, slug, svg: SvgComponent, title }, index) => (
          <div
            key={id}
            className="flex-1 flex relative h-full"
            onPointerEnter={() => setActiveVideoIndex(index)}
            onPointerLeave={() => setActiveVideoIndex(null)}
          >
            <Video
              videoRef={videoRefs[index]}
              url={video.json.secure_url}
              title={video.title}
              poster={poster}
              loop
              muted
              className={`absolute inset-0 h-full object-cover ${getVideoObjectPosition(
                index
              )} opacity-10`}
            />
            <div ref={linksRefs[index]} className="w-2/3 m-auto relative">
              <Link
                to={slug}
                className={`${getClassNamesByTabIsUsedState(tabIsUsed)} block`}
              >
                <SvgComponent title={title} />
              </Link>
            </div>
          </div>
        )
      )}
    </CircleMask>
  )
}

export default ThreeVideosLayout
