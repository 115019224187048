import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/reusables/Layout"
import SEO from "../components/reusables/seo"
import ThreeVideosLayout from "../components/reusables/ThreeVideosLayout"
import getSvgLogoComponent from "../helpers/getSvgLogoComponent"

export default function HomePageTemplate({ data }) {
  console.log(data, "data")
  const homePage = data.contentfulHomePage
  const links = homePage.links.map(({ id, slug, text, logo }, index) => ({
    id,
    slug,
    title: text,
    video: homePage.videos[index],
    poster: homePage.videos[index].poster.localFile.publicURL,
    svg: getSvgLogoComponent(logo),
  }))

  return (
    <>
      <SEO title={data.contentfulHomePage.title} />
      <Layout>
        <section id="main">
          <ThreeVideosLayout links={links} data={data} />
        </section>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulHomePage(slug: { eq: $slug }) {
      title
      node_locale
      links {
        id
        slug
        text
        logo {
          title
        }
      }
      videos {
        title
        json: childContentfulCloudinaryVideoVideoJsonNode {
          secure_url
        }
        poster {
          localFile {
            publicURL
          }
        }
      }
      sphaerenDescriptionBody {
        raw
      }
      squareDescriptionBody {
        raw
      }
      circleLeftDescriptionBody {
        raw
      }
      circleRightDescriptionBody {
        raw
      }
      squareDescriptionTitle
      sphaerenDescriptionTitle
      circleLeftDescriptionTitle
      circleRightDescriptionTitle
    }
  }
`
