import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import addLineBreaks from "../../helpers/addLineBreaks"

const Description = ({ headerText, body }) => {
  const richTextOptions = {
    renderText: addLineBreaks,
  }
  return (
    <>
      <h2 className="lg:text-lg 2xl:text-xl text-primary">{headerText}</h2>
      <div className="text-xs lg:text-sm">
        {renderRichText(body, richTextOptions)}
      </div>
    </>
  )
}

const ThreeVideoLayoutTexts = ({ data, textRefs }) => {
  const homePage = data.contentfulHomePage
  const baseClassName =
    "font-light p-2 lg:p-7 text-center absolute transform opacity-0 invisible "
  return (
    <div className="absolute inset-0 flex items-center">
      <div
        ref={textRefs[0]}
        className={baseClassName + "w-2/3 right-0 translate-x-120"}
      >
        <Description
          headerText={homePage.sphaerenDescriptionTitle}
          body={homePage.sphaerenDescriptionBody}
        />
      </div>
      <div
        ref={textRefs[1]}
        className={baseClassName + "w-1/3 left-0 -translate-x-120"}
      >
        <Description
          headerText={homePage.circleLeftDescriptionTitle}
          body={homePage.circleLeftDescriptionBody}
        />
      </div>
      <div
        ref={textRefs[2]}
        className={baseClassName + "w-1/3 right-0 translate-x-120"}
      >
        <Description
          headerText={homePage.circleRightDescriptionTitle}
          body={homePage.circleRightDescriptionBody}
        />
      </div>
      <div
        ref={textRefs[3]}
        className={baseClassName + "w-2/3 left-0 -translate-x-120"}
      >
        <Description
          headerText={homePage.squareDescriptionTitle}
          body={homePage.squareDescriptionBody}
        />
      </div>
    </div>
  )
}

export default ThreeVideoLayoutTexts
